<template>
    <div></div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import useCurrentUser from '@/libs/app/current-user'

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const queryParams = { ...to.query }
      if (!isEmpty(queryParams)) {
        if (queryParams.spapi_oauth_code) {
          const payload = {
            spapi_oauth_code: queryParams.spapi_oauth_code,
            state: queryParams.state,
            selling_partner_id: queryParams.selling_partner_id,
          }
          vm.onPageRedirect(payload)
        } else {
          if (queryParams.auth && queryParams.auth === 'ebay') {
            const payload = {
              auth: queryParams.auth,
              code: queryParams.code,
              expires_in: queryParams.expires_in,
            }
            vm.onPageRedirect(payload)
          }
          if (queryParams.auth && queryParams.auth === 'shopify') {
            const payload = {
              auth: queryParams.auth,
              code: queryParams.code,
              shop: queryParams.shop,
            }
            vm.onPageRedirect(payload)
          }
          if (queryParams.auth && queryParams.auth === 'walmart') {
            const payload = {
              auth: queryParams.auth,
              code: queryParams.code,
              type: queryParams.type,
              clientId: queryParams.clientId,
              state: queryParams.state,
              sellerId: queryParams.sellerId,
            }
            vm.onPageRedirect(payload)
          }
        }
      } else {
        vm.onPageRedirect()
      }
    })
  },
  setup() {
    const { userCompanyId, userPatmentRequiredDueNotTrial, userPatmentRequiredDueFail } = useCurrentUser()
    return {
      userCompanyId,
      userPatmentRequiredDueNotTrial,
      userPatmentRequiredDueFail,
    }
  },
  methods: {
    onPageRedirect(payload = null) {
      if (this.$can('read', 'resource_dashboards_dashboard_snapshot')) {
        if (payload) {
          this.$router.push({ name: 'dashboard-statistics', query: payload })
        } else {
          this.$router.push({ name: 'dashboard-statistics' })
        }
        return
      }
      if (this.$can('read', 'resource_dashboards_dashboard_summary')) {
        this.$router.push({ name: 'dashboard-summary' })
      }
    },
  },
}
</script>
